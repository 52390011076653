import React, { ReactElement } from 'react'
import { History } from 'history'
import { CaretRightOutlined } from '@ant-design/icons'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import style from './Index.module.less'
import icon from '@imgs/accord-icon.png'

interface Props {
  caseDetail: CaseSchema
  history: History
}

function Main ({ caseDetail, history }: Props): ReactElement {
  return (
    <div className={style.container}>
      <div className={style.header}>
        <img src={icon} alt="" />
        <span>仲裁申请书</span>
      </div>
      <div className={style.block} onClick={() => history.push(`/admin/case-edit/${caseDetail.id}/apply-doc`)}>
        <div className={style['block-left']}>
          <div className={style['block-left-title']}>仲裁申请书</div>
          <div className={style['block-left-content']}>{caseDetail.claim.fileid === undefined || caseDetail.claim.fileid === null ? '无' : '已上传'}</div>
        </div>
        <div className={style['block-right']}>
          <CaretRightOutlined />
        </div>
      </div>
    </div>
  )
}

export default Main
