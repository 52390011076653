import { $get } from '@js/request'
import { ArbitratorSchema } from '@src/components/Schema/ArbitratorSchema'

// 获取仲裁员列表
export async function getArbitrators (current: number, size: number, condition: string): Promise<{ list: ArbitratorSchema[], total: number }> {
  const res = await $get('/api/xd/arbitrator', {
    current,
    page_size: size,
    condition
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}
