import { ColumnsType } from 'antd/es/table'
import { formatDate } from '@src/assets/js/tool'
import { CaseSchema } from '@src/components/Schema/CaseSchema'

export function getColumn (): ColumnsType<CaseSchema> {
  const columns: ColumnsType<CaseSchema> = [
    {
      title: '立案号/案号',
      dataIndex: 'caseno_desc',
      key: 'caseno_desc',
      width: '205px',
      render: (v: string, l) => {
        if (typeof v === 'string') {
          return v
        }
        return l.acceptno_desc_doc
      }
    },
    {
      title: '受理日期',
      dataIndex: 'accept_datetime',
      key: 'accept_datetime',
      width: '120px',
      render: (v: number) => {
        if (typeof v === 'number') {
          return formatDate(v)
        }
      }
    },
    {
      title: '申请人',
      dataIndex: 'applicant_name',
      key: 'applicant_name',
      width: 100,
      ellipsis: true
    },
    {
      title: '被申请人',
      dataIndex: 'respondent_name',
      key: 'respondent_name',
      width: 100,
      ellipsis: true
    },
    {
      title: '金额',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (v: number) => {
        if (typeof v === 'number') {
          return v.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
      }
    }
  ]
  return columns
}
