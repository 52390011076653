import { ReactElement, useState, useEffect } from 'react'
import style from './Index.module.less'
import { message, List } from 'antd'
import classNames from 'classnames'
import { History } from 'history'
import { OrderSchema } from '@components/Schema/OrderSchema'
import { getCaseListRequest } from './ajax'
import { awaitWrap } from '@js/tool'
import LeftMenu from '../../components/LeftMenu/Index'
import Item from './components/Item/Index'

interface Props {
  history: History
}

// 初始化案件列表
async function initCaseList (setLoading: (v: boolean) => void, setDataSource: (v: OrderSchema[]) => void): Promise<void> {
  setLoading(true)
  const [e, d] = await awaitWrap(getCaseListRequest())
  setLoading(false)
  if (e !== null) {
    await undefined
  } else if (d === null) {
    await message.error('订单列表未返回')
  } else {
    setDataSource(d)
  }
}

function Main ({ history }: Props): ReactElement {
  const [loading, setLoading] = useState(false)
  const [dataSource, setDataSource] = useState<OrderSchema[]>([])
  const [isFixed, setIsFixed] = useState(window.document.documentElement.offsetWidth >= 1280)

  useEffect(() => {
    initCaseList(setLoading, setDataSource).catch(e => console.error(e))
    function initFixed (): void {
      setIsFixed(window.document.documentElement.offsetWidth >= 1280)
    }
    window.addEventListener('resize', initFixed)
    return () => {
      window.removeEventListener('resize', initFixed)
    }
  }, [])
  return (
    <div className={style.container}>
      <div className={classNames(style['left-block'], { [style.fixed]: isFixed })}>
        <LeftMenu />
      </div>
      <div className={style.content}>
        <List dataSource={dataSource} loading={loading} renderItem={d => <Item data={d} />} />
      </div>
      <div className={classNames(style['right-block'], { [style.fixed]: isFixed })}>
      </div>
    </div>
  )
}

export default Main
