import { ReactElement, useState, useEffect } from 'react'
import style from './Index.module.less'
import { Skeleton, Table, Checkbox, Space, Button, Modal, message } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { OrderCaseSchema, OrderSchema } from '@src/components/Schema/OrderSchema'
import { useParams, useHistory } from 'react-router-dom'
import { getDataRequest, cancelRequest, payRequest } from './ajax'
import { awaitWrap, formatDate, formatMoney } from '@src/assets/js/tool'
import { getColumn } from './util'

const { confirm } = Modal

// 确认提交
function cancelSubmit (d: OrderSchema, callback: () => void): void {
  confirm({
    icon: <ExclamationCircleOutlined />,
    content: '您确定要取消吗？',
    onOk: () => {
      cancelHandle(d.id, callback).catch(e => console.error(e))
    }
  })
}

async function cancelHandle (id: string, callback: () => void): Promise<void> {
  const hide = message.loading('取消中', 120)
  const [e] = await awaitWrap(cancelRequest(id))
  hide()
  if (e === null) {
    callback()
    message.success('取消成功').then(null, null)
  }
}

async function payHandle (data: OrderSchema, t: string): Promise<void> {
  const hide = message.loading('提交中', 120)
  const [e, d] = await awaitWrap(payRequest(data.id, t))
  hide()
  if (e === null && d !== null) {
    if (d.pay_type_select === 'icbc2b') {
      location.assign(d.redirectParam)
    } else if (d.pay_type_select === 'icbc2c') {
      document.write(d.redirectParam)
    }
  }
}

async function initData (id: string, setLoading: (v: boolean) => void, setData: (v: OrderSchema) => void): Promise<void> {
  setLoading(true)
  const [e, d] = await awaitWrap(getDataRequest(id))
  setLoading(false)
  if (e === null && d !== null) {
    setData(d)
  }
}

function Main (): ReactElement {
  const h = useHistory()
  const { id } = useParams<{ id: string }>()
  const [data, setData] = useState<OrderSchema | null>(null)
  const [loading, setLoading] = useState(false)
  const [dataSource, setDataSource] = useState<OrderCaseSchema[]>([])
  const [t, setT] = useState('icbc2b')
  useEffect(() => {
    if (typeof id === 'string') {
      initData(id, setLoading, setData).catch(e => console.error(e))
    }
  }, [id])
  useEffect(() => {
    if (data !== null) {
      setDataSource(data.details)
    }
  }, [data])
  return (
    <div className={style.container}>
      <Skeleton active loading={loading} />
      {
        data !== null &&
        <>
          <div className={style['title-line']}>缴费单</div>
          <div className={style['title-line']}>
            <span>缴费单号：{data.ordernum}</span>
            <span className={style['title-line-time']}>{formatDate(data.create_datetime, 'YYYY-MM-DD HH:mm:ss')}</span>
          </div>
          <div className={style['table-block']}>
            <Table dataSource={dataSource} rowKey='id' columns={getColumn()} loading={loading} pagination={false} />
          </div>
          <div className={style['title-line']}>付款方式</div>
          <div className={style['check-line']}>
            <Checkbox checked={t === 'icbc2b'} onChange={() => setT('icbc2b')}>使用企业账户支付</Checkbox>
          </div>
          <div className={style['check-line']}>
            <Checkbox checked={t === 'icbc2c'} onChange={() => setT('icbc2c')}>使用个人账户支付</Checkbox>
          </div>
          <div className={style['operate-line']}>
            <Space size={36}>
              <span className={style['total-num']}>共 {data.details.length} 项</span>
              <span className={style['pay-info']}>
                <span className={style['pay-label']}>应付</span>
                <span className={style['pay-money']}>¥ {formatMoney(data.amount)}</span>
              </span>
              <Space size={12}>
                <Button onClick={() => cancelSubmit(data, () => h.push('/admin/case-pay-list/to-pay-list'))}>取消订单</Button>
                <Button type='primary' onClick={async () => await payHandle(data, t)}>去缴费</Button>
              </Space>
            </Space>
          </div>
        </>
      }
    </div>
  )
}

export default Main
