import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { History } from 'history'
import { scrollToAnchor } from '@js/tool'
const { confirm } = Modal
export function confirmArbitrator (refreshOpereate: (k: string) => void, h: History): void {
  confirm({
    icon: <ExclamationCircleOutlined />,
    content: '您确认在线选择仲裁员？',
    onOk: async () => {
      await refreshOpereate('party_zuting')
      setTimeout(() => {
        scrollToAnchor('#party_zuting', h)
      }, 500)
    }
  })
}
