import React, { ReactElement, useRef } from 'react'
import { Modal, Form, Input, FormInstance, message } from 'antd'
import { concatRequest } from './ajax'
import { awaitWrap } from '@js/tool'

const { Item } = Form

interface Props {
  visible: boolean
  hide: () => void
  refreshHandle: () => void
}

interface FormData {
  caseno: string
  linkcode: string
}

async function concatHandle (d: FormData, hide: () => void, refreshHandle: () => void): Promise<void> {
  const h = message.loading('提交中', 120)
  const [e] = await awaitWrap(concatRequest(d.caseno, d.linkcode))
  h()
  if (e !== null) {
    return undefined
  }
  message.success('关联成功').then(null, null)
  hide()
  refreshHandle()
}

function Main ({ visible, hide, refreshHandle }: Props): ReactElement {
  const formRef = useRef<FormInstance>(null)
  return (
    <Modal
      title='关联案件'
      visible={visible}
      maskClosable={false}
      destroyOnClose
      onCancel={hide}
      onOk={() => formRef.current?.submit()}
    >
      <Form labelCol={{ flex: '108px' }} ref={formRef} onFinish={async d => await concatHandle(d, hide, refreshHandle)}>
        <Item label='案号' name='caseno' rules={[{ required: true, message: '案号不能为空' }]}>
          <Input placeholder='请输入' />
        </Item>
        <Item label='送达签收码' name='linkcode' rules={[{ required: true, message: '送达签收码不能为空' }]}>
          <Input placeholder='请输入' />
        </Item>
      </Form>
    </Modal>
  )
}

export default Main
