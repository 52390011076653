import { $get } from '@js/request'
import { OrderSchema } from '@components/Schema/OrderSchema'

// 获取案件列表
export async function getCaseListRequest (): Promise<OrderSchema[]> {
  const condition = [{ k: 'status', o: 'in', v: '完成,支付成功,支付失败' }]
  const res = await $get('/api/xd/order', {
    page_size: 1000,
    current: 1,
    condition: JSON.stringify(condition)
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data.list.map((li: OrderSchema, i: number) => {
    li.order = i + 1
    return li
  })
}
