import React, { ReactElement, useState, useEffect } from 'react'
import { CaretRightOutlined } from '@ant-design/icons'
import { History } from 'history'
import { connect } from 'react-redux'
import { CaseSchema } from '@components/Schema/CaseSchema'
import { PartySchema } from '@components/Schema/PartySchema'
import { BASIC_STATE } from '@redux/reducers/basic'
import { USERINFO } from '@redux/actions/basic'
import style from './Index.module.less'
import applicantIcon from '@imgs/applicant-icon.png'
import respondentIcon from '@imgs/respondent-icon.png'
import classNames from 'classnames'
import { validateParty } from '../../tool'

interface LineProps {
  data: PartySchema
  userInfo: USERINFO
  onClick: (v: string | undefined) => void
}
function Line ({ data, userInfo, onClick }: LineProps): ReactElement {
  const [self, setSelf] = useState(false)
  const [unfull, setUnfull] = useState(false)
  const names = classNames(style['block-line'], { [style.self]: self, [style.unfull]: unfull })
  useEffect(() => {
    setSelf(userInfo !== null && data !== null && data.accountid === userInfo.id)
  }, [data, userInfo])
  useEffect(() => {
    if (data !== null) {
      setUnfull(!validateParty(data))
    }
  }, [data])
  return (
    <div className={names} onClick={() => onClick(data.id)}>
      <div className={style['block-line-left']}>
        <span>{data.name}</span>
      </div>
      <div className={style['block-line-right']}>
        <CaretRightOutlined />
      </div>
    </div>
  )
}

// 编辑当事人
function editParty (v: string, caseid: string, type: string, history: History): void {
  history.push(`/admin/case-edit/${caseid}/party/${type}/${v}`)
}

// 添加当事人
function addParty (caseid: string, type: string, history: History): void {
  history.push(`/admin/case-edit/${caseid}/party/${type}`)
}

interface Props {
  caseDetail: CaseSchema
  userInfo: USERINFO
  history: History
  type: 'applicant' | 'respondent'
}
function Main ({ caseDetail, userInfo, history, type }: Props): ReactElement {
  const [label, setLabel] = useState('申请人')
  const [list, setList] = useState<PartySchema[]>([])
  const names = classNames({ [style.respondent]: type === 'respondent' })
  useEffect(() => {
    setLabel(type === 'applicant' ? '申请人' : '被申请人')
    setList(type === 'applicant' ? caseDetail.applicants : caseDetail.respondents)
  }, [type, caseDetail])
  return (
    <div className={names}>
      <div className={style.header}>
        <img src={type === 'applicant' ? applicantIcon : respondentIcon} alt="" />
        <span>{label}</span>
      </div>
      <div className={style.block}>
        {
          list.map(li => {
            return (
              <Line key={li.id} data={li} userInfo={userInfo} onClick={(id) => editParty(id as string, caseDetail.id, type, history)} />
            )
          })
        }
        <div className={style['btn-line']}>
          <span>{label}</span>
          <div className={style['btn-box']}>
            <button className={style.btn} onClick={() => addParty(caseDetail.id, type, history)}>添加{label}</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo
  }
}, dispatch => {
  return {}
})(Main)
