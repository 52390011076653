import { ReactElement, useState, useEffect, Key, HTMLAttributes } from 'react'
import style from './Index.module.less'
import { message, Table, Space, Button, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import _ from 'lodash'
import { History } from 'history'
import { CaseSchema } from '@components/Schema/CaseSchema'
import { getCaseListRequest, createBillRequest } from './ajax'
import { awaitWrap } from '@js/tool'
import SearchInput from './components/SearchInput/Index'
import { getColumn } from './util'
import LeftMenu from '../../components/LeftMenu/Index'

const { confirm } = Modal

function getTotalFee (rows: CaseSchema[]): string {
  let fee = 0
  rows.forEach(r => {
    if (typeof r.amount === 'number') {
      fee += r.amount
    }
  })
  if (fee === 0) {
    return '0'
  }
  return fee.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function submitConfirm (ids: string[], h: History): void {
  confirm({
    icon: <ExclamationCircleOutlined />,
    content: '您确认去缴费吗？',
    onOk: async () => {
      submitHandle(ids, h).catch(e => console.error(e))
    }
  })
}

async function submitHandle (ids: string[], h: History): Promise<void> {
  const hide = message.loading('创建订单中', 120)
  const [e, d] = await awaitWrap(createBillRequest(ids.join()))
  hide()
  if (e === null && d !== null) {
    message.success('创建成功').then(null, null)
    h.push(`/admin/case-pay-list/to-pay-detail/${d}`)
  }
}

interface Props {
  history: History
}

// 初始化案件列表
async function initCaseList (setLoading: (v: boolean) => void, setDataSource: (v: CaseSchema[]) => void, searchText: string, setSelectedRowKeys: (v: Key[]) => void, setSelectedRows: (v: CaseSchema[]) => void): Promise<void> {
  // setSelectedRowKeys([])
  // setSelectedRows([])
  setLoading(true)
  const [e, d] = await awaitWrap(getCaseListRequest(searchText))
  setLoading(false)
  if (e !== null) {
    await undefined
  } else if (d === null) {
    await message.error('案件列表未返回')
  } else {
    setDataSource(d)
  }
}

const changeFn: (setLoading: (v: boolean) => void, setDataSource: (v: CaseSchema[]) => void, v: string, setSelectedRowKeys: (v: Key[]) => void, setSelectedRows: (v: CaseSchema[]) => void) => void = _.debounce(initCaseList, 500)

function Main ({ history }: Props): ReactElement {
  const [loading, setLoading] = useState(false)
  const [dataSource, setDataSource] = useState<CaseSchema[]>([])
  const [searchText, setSearchText] = useState('')
  const [isFixed, setIsFixed] = useState(window.document.documentElement.offsetWidth >= 1280)
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
  const [selectedRows, setSelectedRows] = useState<CaseSchema[]>([])

  const rowSelection = {
    selectedRowKeys,
    selectedRows,
    preserveSelectedRowKeys: true,
    onChange: (keys: Key[], rows: CaseSchema[]) => {
      setSelectedRowKeys(keys)
      setSelectedRows(rows)
    }
  }
  const onRow = function (record: CaseSchema): HTMLAttributes<HTMLElement> {
    return {
      onClick: () => {
        const s = [...selectedRowKeys]
        const rs = [...selectedRows]
        const i = s.indexOf(record.id)
        if (i !== -1) {
          s.splice(i, 1)
          rs.splice(i, 1)
        } else {
          s.push(record.id)
          rs.push(record)
        }
        setSelectedRowKeys(s)
        setSelectedRows(rs)
      }
    }
  }

  useEffect(() => {
    changeFn(setLoading, setDataSource, searchText, setSelectedRowKeys, setSelectedRows)
  }, [searchText])
  useEffect(() => {
    function initFixed (): void {
      setIsFixed(window.document.documentElement.offsetWidth >= 1280)
    }
    window.addEventListener('resize', initFixed)
    return () => {
      window.removeEventListener('resize', initFixed)
    }
  }, [])
  return (
    <div className={style.container}>
      <div className={classNames(style['left-block'], { [style.fixed]: isFixed })}>
        <LeftMenu />
      </div>
      <div className={style.content}>
        <SearchInput value={searchText} onChange={v => setSearchText(v)} />
        <div className={style.table}>
          <Table dataSource={dataSource} rowKey={record => record.id} onRow={onRow} rowSelection={rowSelection} loading={loading} pagination={false} columns={getColumn()} />
        </div>
      </div>
      <div className={classNames(style['right-block'], { [style.fixed]: isFixed })}>
      </div>
      {
        selectedRows.length > 0 && (
          <div className={style['pay-line']}>
            <Space size={24}>
              <span className={style['pay-line-info']}>
                <span className={style['pay-line-title']}>应付</span>
                <span className={style['pay-line-money']}>¥ {getTotalFee(selectedRows)}</span>
              </span>
              <Button type='primary' onClick={() => submitConfirm(selectedRowKeys as string[], history)}>去缴费({selectedRows.length})</Button>
            </Space>
          </div>
        )
      }
    </div>
  )
}

export default Main
