import React, { ReactElement, useEffect, useState } from 'react'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import style from './Index.module.less'
import { getDefinceRequest } from './ajax'
import { ClaimSchema } from '@src/components/Schema/ClaimSchema'
import { DefinceSchema } from '@src/components/Schema/DefinceSchema'
import { awaitWrap } from '@src/assets/js/tool'
import { message, Button } from 'antd'
import List from './components/Item/Index'
import { useHistory } from 'react-router-dom'

interface Props {
  data: CaseSchema
}

// 获取请求列表的答辩信息，并过滤没有答辩的请求
async function initDefinceList (caseid: string, claims: ClaimSchema[]): Promise<ClaimSchema[]> {
  const res = await Promise.all(claims.map(async claim => await getDefinceRequest(caseid, claim.id)))
  const result: ClaimSchema[] = []
  res.forEach((list: DefinceSchema[], index: number) => {
    if (list.length > 0) {
      claims[index].defince = list
      result.push(claims[index])
    }
  })
  return result
}

// 初始化仲裁请求的答辩
async function initClaimsDefince (caseid: string, claims: ClaimSchema[], setClaims: (v: ClaimSchema[]) => void): Promise<void> {
  const [e, d] = await awaitWrap(initDefinceList(caseid, claims))
  if (e !== null) {
    await message.error(e.message)
  } else if (d !== null) {
    setClaims(d)
  }
}

async function initData (c: CaseSchema, setClaims: (v: ClaimSchema[]) => void, setCclaims: (v: ClaimSchema[]) => void): Promise<void> {
  const hide = message.loading('加载中', 120)
  await Promise.all([initClaimsDefince(c.id, c.claims, setClaims), initClaimsDefince(c.id, c.cclaims, setCclaims)])
  hide()
}

function Main ({ data }: Props): ReactElement {
  const h = useHistory()
  const [claims, setClaims] = useState<ClaimSchema[]>([])
  const [cclaims, setCclaims] = useState<ClaimSchema[]>([])
  useEffect(() => {
    if (data !== null) {
      initData(data, setClaims, setCclaims).catch(e => console.error(e))
    }
  }, [data])
  if (claims.length === 0 && cclaims.length === 0) {
    return <span />
  }
  return (
    <div id='party_dabian'>
      <div className={style.header}>答辩</div>
      {
        claims.map(li => {
          return <List data={li} key={li.id} />
        })
      }
      {
        cclaims.map(li => {
          return <List data={li} key={li.id} anti />
        })
      }
      <div className={style['btn-line']}>
        <Button type='primary' ghost onClick={() => h.push(`/admin/case-detail/${data.id}/defince`)}>添加答辩</Button>
      </div>
    </div>
  )
}

export default Main
