import React, { ReactElement, useState, useEffect } from 'react'
import { CaseSchema } from '@components/Schema/CaseSchema'
import { BASIC_STATE } from '@redux/reducers/basic'
import { USERINFO } from '@redux/actions/basic'
import { History } from 'history'
import { connect } from 'react-redux'
import { CaretRightOutlined } from '@ant-design/icons'
import style from './Index.module.less'
import icon from '@imgs/evidence-icon.png'
import { EvidenceSchema } from '@src/components/Schema/EvidenceSchema'

interface LineProps {
  data: EvidenceSchema
  userInfo: USERINFO
  onClick: (v: string | undefined) => void
}

function Line ({ data, userInfo, onClick }: LineProps): ReactElement {
  return (
    <div className={style['block-line']} onClick={() => onClick(data.id)}>
      <div className={style['block-line-left']}>
        <span>
          <span>{data.name}：</span>
          <span className={style.value}>{data.proof}</span>
        </span>
      </div>
      <div className={style['block-line-right']}>
        <CaretRightOutlined />
      </div>
    </div>
  )
}

interface Props {
  caseDetail: CaseSchema
  userInfo: USERINFO
  history: History
}

function Main ({ caseDetail, userInfo, history }: Props): ReactElement {
  const [list, setList] = useState<EvidenceSchema[]>([])
  useEffect(() => {
    setList(caseDetail.evidence)
  }, [caseDetail])
  return (
    <div>
      <div className={style.header}>
        <img src={icon} alt="" />
        <span>证据材料</span>
      </div>
      <div className={style.block}>
        {
          list.map((li, i) => {
            return (
              <Line key={i} data={li} userInfo={userInfo} onClick={id => history.push(`/admin/case-edit/${caseDetail.id}/evidence/${id as string}`)} />
            )
          })
        }
        <div className={style['btn-line']}>
          <span>证据材料</span>
          <div className={style['btn-box']}>
            <button className={style.btn} onClick={() => history.push(`/admin/case-edit/${caseDetail.id}/evidence`)}>添加证据</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo
  }
}, dispatch => {
  return {}
})(Main)
