import { ReactElement } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { History, Location } from 'history'
import UnPayCaseList from './pages/UnPayCaseList/Index'
import ToPayList from './pages/ToPayList/Index'
import PaiedList from './pages/PaiedList/Index'
import ToPayDetail from './pages/ToPayDetail/Index'

interface Props {
  history: History
  location: Location
}

function Main ({ history, location }: Props): ReactElement {
  return (
    <Switch location={location}>
      <Route exact path='/admin/case-pay-list/case-list' component={UnPayCaseList} />
      <Route exact path='/admin/case-pay-list/to-pay-list' component={ToPayList} />
      <Route exact path='/admin/case-pay-list/paied-list' component={PaiedList} />
      <Route exact path='/admin/case-pay-list/to-pay-detail/:id' component={ToPayDetail} />
      <Route exact path='/admin/case-pay-list*' component={() => <Redirect to='/admin/case-list' />} />
    </Switch>
  )
}

export default Main
