import { $put } from '@js/request'

// 关联案件请求
export async function concatRequest (scaseno: string, linkcode: string): Promise<void> {
  const res = await $put('/api/xd/case/linkCase', {
    scaseno,
    linkcode
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}
