import React, { ReactElement, useContext } from 'react'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import style from './Index.module.less'
import { Context } from '../../context'
import PersonItem from './components/PersonItem/Index'
import Result from './components/Result/Index'

interface Props {
  data: CaseSchema
}

function Main ({ data }: Props): ReactElement {
  const c = useContext(Context)
  return (
    <div id='party_zuting'>
      <div className={style.header}>组庭</div>
      {c.courtItems.map((li, i) => {
        return <PersonItem key={i} data={li} />
      })}
      <Result data={c.courtItems} />
    </div>
  )
}

export default Main
