import { $get, $put } from '@js/request'
import { OrderSchema } from '@src/components/Schema/OrderSchema'

// 获取详情数据
export async function getDataRequest (id: string): Promise<OrderSchema> {
  const res = await $get(`/api/xd/order/${id}`)
  return res.data
}

// 取消
export async function cancelRequest (orderid: string): Promise<void> {
  const res = await $put('/api/xd/order/cancel', {
    orderid
  })
  return res.data
}

// 去付款
export async function payRequest (orderid: string, t: string): Promise<{ pay_type_select: string, redirectParam: string, return_msg: string, transOk: number, pageinfo: string }> {
  const res = await $put('/api/xd/order/prepay', {
    orderid,
    pay_type_select: t,
    callback_url: `${location.protocol}//${location.host}/admin/case-pay-list/to-pay-detail/${orderid}`
  })
  return res.data
}
