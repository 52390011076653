import { formatDate } from '@src/assets/js/tool'
import { ClaimSchema } from '@src/components/Schema/ClaimSchema'
import { DefinceSchema } from '@src/components/Schema/DefinceSchema'
import React, { ReactElement } from 'react'
import Claim from '../../../Claim/Index'
import style from './Index.module.less'

interface Props {
  data: ClaimSchema
  anti?: boolean
}

function Item ({ data }: {data: DefinceSchema}): ReactElement {
  const files = data.file instanceof Array ? data.file : []
  return (
    <div className={style.block}>
      <div className={style['block-header']}>
        <span className={style['block-label']}>答辩人</span>
        <span>{data.defender}</span>
        <span className={style['block-date']}>{formatDate(data.create_datetime, 'YYYY-MM-DD HH:mm')}</span>
      </div>
      <div className={style.statement}>{data.statement}</div>
      <div className={style.files}>
        {files.map((li, i) => {
          return (
            <span className={style.file} key={i}>
              <a href={li.url} >{li.original_filename}</a>
            </span>
          )
        })}
      </div>
    </div>
  )
}

function Main ({ data, anti = false }: Props): ReactElement {
  return (
    <div>
      <Claim data={data} nopad anti={anti} time />
      <div className={style.header}>仲裁答辩</div>
      {data.defince?.map(li => {
        return <Item key={li.id} data={li} />
      })}
    </div>
  )
}

export default Main
