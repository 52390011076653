import React from 'react'
import { createBrowserHistory } from 'history'
import {
  Route,
  Router,
  Redirect,
  RouteComponentProps,
  Switch
} from 'react-router-dom'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { RouterConfig } from './routerConfig'
import './router.less'
import { BASIC_STATE } from '@src/redux/reducers/basic'
import { USERINFO } from '@src/redux/actions/basic'

export const history = createBrowserHistory()

interface RProps {
  token: string
  userInfo: USERINFO
}

interface Props extends RouteComponentProps<any, any, any> {

}

function R ({ token, userInfo }: RProps): React.ReactElement {
  return (
    <Router history={history}>
      <div className='container'>
        {RouterConfig.map(({ path, auth, checkeAuth, component: Component }) => {
          const nodeRef = React.useRef(null)
          return (
            <Route key={path} exact path={path}>
              {(props: Props) => {
                const { match } = props
                // 验证是否需要登录，否则跳转到登录页
                if (match !== null && match.path === path && auth) {
                  if (token === '') {
                    return (
                      <Redirect to='/login' />
                    )
                  } else if (checkeAuth === true && userInfo.is_auth === 0) {
                    return (
                      <Redirect to='/verification' />
                    )
                  }
                }
                return (
                  <CSSTransition
                  in={props.match != null}
                  nodeRef={nodeRef}
                  timeout={300}
                  classNames="page"
                  unmountOnExit
                >
                  <div className="page" ref={nodeRef}>
                    <Component {...props} />
                  </div>
                </CSSTransition>
                )
              }}
            </Route>
          )
        })}
        <Switch>
          {RouterConfig.map(({ path }) => <Route key={path} path={path} exact />)}
          <Route path='/*' render={() => <Redirect to='/admin' />} />
        </Switch>
      </div>
    </Router>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    token: state.basic.token,
    userInfo: state.basic.userInfo
  }
}, () => {
  return {}
})(R)
