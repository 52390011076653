import { ReactElement, useState, useEffect } from 'react'
import { OrderSchema } from '@src/components/Schema/OrderSchema'
import style from './Index.module.less'
import { DownOutlined, UpOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Space, Button, Tooltip, Modal, message } from 'antd'
import { awaitWrap, formatDate, formatMoney } from '@src/assets/js/tool'
import classNames from 'classnames'
import { Link, useHistory } from 'react-router-dom'
import { cancelRequest } from './ajax'

const { confirm } = Modal

// 确认提交
function cancelSubmit (d: OrderSchema, callback: () => void): void {
  confirm({
    icon: <ExclamationCircleOutlined />,
    content: '您确定要取消吗？',
    onOk: () => {
      cancelHandle(d.id, callback).catch(e => console.error(e))
    }
  })
}

async function cancelHandle (id: string, callback: () => void): Promise<void> {
  const hide = message.loading('取消中', 120)
  const [e] = await awaitWrap(cancelRequest(id))
  hide()
  if (e === null) {
    callback()
    message.success('取消成功').then(null, null)
  }
}

interface Props {
  data: OrderSchema
  refreshData: () => void
}

function Main ({ data, refreshData }: Props): ReactElement {
  const h = useHistory()
  const [visible, setVisible] = useState(false)
  const [totalFee, setTotalFee] = useState('')
  const [list, setList] = useState(data.details.slice(0, 4))
  useEffect(() => {
    if (visible) {
      setList(data.details)
    } else {
      setList(data.details.slice(0, 4))
    }
  }, [visible])
  useEffect(() => {
    let total = 0
    data.details.forEach(li => {
      total += li.amount
    })
    setTotalFee(formatMoney(total))
  }, [data])
  return (
    <div className={style.container}>
      <div className={style.header}>
        <span className={style['header-title']}>缴费单号：224428152819</span>
        <span className={style['header-date']}>提交时间 {formatDate(data.create_datetime, 'YYYY-MM-DD HH:mm:ss')}</span>
      </div>
      <table className={style.table}>
        <thead>
          <tr>
            <th className={style['table-th']}>立案号/案号</th>
            <th className={style['table-th']}>受理日期</th>
            <th className={style['table-th']}>申请人</th>
            <th className={style['table-th']}>被申请人</th>
            <th className={style['table-th']}>金额</th>
          </tr>
        </thead>
        <tbody>
          {
            list.map((li, i) => {
              const caseno = typeof li.caseno_desc === 'string' ? li.caseno_desc : li.acceptno_desc_doc
              return (
                <tr key={i}>
                  <td className={style['table-td']}>
                    <Tooltip title={caseno}><Link to={`/admin/case-detail/${li.caseid}`}>{caseno}</Link></Tooltip>
                  </td>
                  <td className={style['table-td']}>{formatDate(li.accept_datetime)}</td>
                  <td className={style['table-td']}>
                    <Tooltip title={li.applicant_name}>
                      {li.applicant_name}
                    </Tooltip>
                  </td>
                  <td className={style['table-td']}>
                    <Tooltip title={li.respondent_name}>
                      {li.respondent_name}
                    </Tooltip>
                  </td>
                  <td className={style['table-td']}>{formatMoney(li.amount)}</td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      <div className={style['total-line']}>
        {data.details.length > 4 && (
          <button className={style['total-btn']} onClick={() => setVisible(!visible)}>
            <span>{visible ? '收起' : '显示更多'}</span>
            {visible ? <UpOutlined /> : <DownOutlined />}
          </button>
        )}
        <span className={style['total-info']}>
          <span>共</span>
          <span>{data.details.length}</span>
          <span>项</span>
        </span>
        <span className={style['total-fee']}>
          <span>应付</span>
          <span>¥ {totalFee}</span>
        </span>
      </div>
      <div className={style['operate-line']}>
        <span className={classNames({ [style['operate-to-pay']]: data.status === '支付中', [style['operate-examine']]: data.status === '审核中' })}>{data.status}</span>
        <Space size={48}>
          <span className={style['operate-type']}>交费类目：{data.type}</span>
          <Space size={12}>
            <Button className={style['operate-btn']} onClick={() => cancelSubmit(data, refreshData)}>取消订单</Button>
            {data.status === '支付中' && <Button className={style['operate-btn']} danger ghost onClick={() => h.push(`/admin/case-pay-list/to-pay-detail/${data.id}`)}>付 款</Button>}
            {data.status === '审核中' && <Button type='primary' ghost>支付凭证</Button>}
          </Space>
        </Space>
      </div>
    </div>
  )
}

export default Main
