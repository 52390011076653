import React, { ReactElement, useRef, useState, useEffect, RefObject } from 'react'
import { Form, Input, Space, Button, Modal, FormInstance, message } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { History } from 'history'
import { match } from 'react-router-dom'
import { connect } from 'react-redux'
import { CaseSchema } from '@components/Schema/CaseSchema'
import style from './Index.module.less'
import Dictionary from '@src/assets/js/dictionary'
import backIcon from '@imgs/back-arrow.png'
import { EvidenceSchema } from '@src/components/Schema/EvidenceSchema'
import Upload, { FileType, uploadFile } from '@src/components/Upload/Index'
import { awaitWrap, getSubmittalByAccountid } from '@js/tool'
import { evdenceRequest, delEvidenceRequest } from './ajax'
import { BASIC_STATE } from '@redux/reducers/basic'
import { USERINFO } from '@redux/actions/basic'

const { Item } = Form
const { TextArea } = Input
const { confirm } = Modal

interface Params {
  caseid: string
  id?: string
}

interface Props {
  history: History
  match: match<Params>
  caseDetail: CaseSchema
  dictionary: Dictionary
  refreshCase: () => void
  userInfo: USERINFO
}

interface FormProps {
  id: string
  name: string
  proof: string
  file?: FileType[]
  fileid?: string
  caseid?: string
  [p: string]: any
}

async function submitHandle (d: FormProps, caseDetail: CaseSchema, history: History, userInfo: USERINFO, refreshCase: () => void): Promise<void> {
  const o = getSubmittalByAccountid(userInfo.id, caseDetail)
  const id = d.id
  if (d.file instanceof Array && d.file.length > 0) {
    const [e, files] = await awaitWrap(uploadFile(d.file))
    if (e !== null) {
      return undefined
    } else if (files === null) {
      return await message.error('文件未返回')
    }
    d.fileid = files.map(li => li.id).join()
  }
  d.caseid = caseDetail.id
  d.submittal = userInfo.realname
  d.submittalid = userInfo.id
  if (o.obj !== null) {
    d.submittal_type = o.type
    d.structure = o.obj.structure
  }
  delete d.file
  const hide = message.loading('提交中', 120)
  const [e] = await awaitWrap(evdenceRequest(d))
  hide()
  if (e !== null) {
    return undefined
  }
  history.push(`/admin/case-edit/${caseDetail.id}`)
  refreshCase()
  await message.success(id === undefined ? '新增成功' : '修改成功')
}

// 表单数据初始化
function initData (caseDetail: CaseSchema, id: string | undefined, formRef: RefObject<FormInstance>): void {
  const c = formRef.current
  if (c === null) {
    return undefined
  }
  if (id === undefined) {
    c.resetFields()
    return undefined
  }
  let o: EvidenceSchema | null = null
  const l: EvidenceSchema[] = caseDetail.evidence
  for (let i = 0; i < l.length; i++) {
    if (l[i].id === id) {
      o = l[i]
      break
    }
  }
  if (o === null) {
    return undefined
  }
  if (o.file instanceof Array) {
    o.file.forEach(li => {
      li.name = li.original_filename
    })
  }
  c.setFieldsValue(o)
}

// 删除
async function delHandle (caseDetail: CaseSchema, id: string, history: History, refreshCase: () => void): Promise<void> {
  const [e] = await awaitWrap(delEvidenceRequest(id))
  if (e !== null) {
    return undefined
  }
  history.push(`/admin/case-edit/${caseDetail.id}`)
  refreshCase()
  await message.success('删除成功')
}

// 删除二次确认
function confirmDel (caseDetail: CaseSchema, id: string | undefined, history: History, refreshCase: () => void): void {
  confirm({
    icon: <ExclamationCircleOutlined />,
    content: '您确定要删除当前请求吗？',
    onOk: () => {
      if (id === undefined) {
        return undefined
      }
      delHandle(caseDetail, id, history, refreshCase).catch(e => console.error(e))
    }
  })
}

function Main ({ history, caseDetail, match, userInfo, refreshCase }: Props): ReactElement {
  const formRef = useRef(null)
  const [isModify, setIsModify] = useState(false)
  useEffect(() => {
    if (match !== null) {
      setIsModify(match.params.id !== undefined)
    }
  }, [match])
  useEffect(() => {
    initData(caseDetail, match.params.id, formRef)
  }, [caseDetail])
  return (
    <div className={style.container}>
      <div className={style.header}>
        <span className={style['header-icon']} onClick={() => history.goBack()}>
          <img src={backIcon} />
        </span>
        <span className={style['header-title']}>证据材料</span>
      </div>
      <Form ref={formRef} className={style.form} labelCol={{ flex: '138px' }} labelAlign='left' colon={false} onFinish={async d => await submitHandle(d, caseDetail, history, userInfo, refreshCase)}>
        <Item name='id' noStyle><span /></Item>
        <Item label='证据名称' name='name' rules={[{ required: true, message: '证据名称不能为空' }]}>
          <Input className={style.long} placeholder='请输入证据名称' />
        </Item>
        <Item label='证明内容' name='proof' rules={[{ required: true, message: '证明内容不能为空' }]}>
          <TextArea rows={5} className={style.long} placeholder='请输入证明内容' />
        </Item>
        <Item label='证据文件' name='file' rules={[{ required: true, message: '证据文件不能为空' }]}>
          <Upload accept='.jpg,.jpeg,.png,.pdf,.doc,.docx,.xlsx,.xls' />
        </Item>
        <div className={style['btn-line']}>
          <Space size='middle'>
            {isModify ? <Button onClick={() => confirmDel(caseDetail, match.params.id, history, refreshCase)}>删除</Button> : null}
            <Button type='primary' htmlType='submit'>确认</Button>
          </Space>
        </div>
      </Form>
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo
  }
}, dispatch => {
  return {}
})(Main)
