import React, { ReactElement, useState, useEffect } from 'react'
import { FileSchema } from '@src/components/Schema/FileSchema'
import frontIcon from '@imgs/front.jpeg'
import backIcon from '@imgs/back.png'
import style from './Index.module.less'
import { isEmptyString } from '@js/tool'

interface Props {
  front?: FileSchema[]
  back?: FileSchema[]
}

function IdImg ({ url, isFront = false }: { url: string, isFront?: boolean }): ReactElement {
  if (isEmptyString(url)) {
    return <img src={isFront ? frontIcon : backIcon} />
  }
  return (
    <a href={url} target='_blank'>
      <img src={url} />
    </a>
  )
}

function Main ({ front, back }: Props): ReactElement {
  const [frontUrl, setFrontUrl] = useState('')
  const [backUrl, setBacktUrl] = useState('')
  useEffect(() => {
    if (front instanceof Array && front.length > 0) {
      setFrontUrl(front[0].url)
    }
  }, [front])
  useEffect(() => {
    if (back instanceof Array && back.length > 0) {
      setBacktUrl(back[0].url)
    }
  }, [back])
  return (
    <div className={style.container}>
      {
        !isEmptyString(frontUrl)
          ? (
            <div className={style.card}>
              <div className={style['card-img']}>
                <IdImg url={frontUrl} isFront />
              </div>
              <div className={style['card-title']}>人像面</div>
            </div>
            )
          : null
      }
      {
        !isEmptyString(backUrl)
          ? (
            <div className={style.card}>
              <div className={style['card-img']}>
                <IdImg url={backUrl} />
              </div>
              <div className={style['card-title']}>国徽面</div>
            </div>
            )
          : null
      }

    </div>
  )
}

export default Main
