import React, { ReactElement, useState, useEffect } from 'react'
import moment from 'moment'
import style from './Index.module.less'
import icon from '@imgs/arbitraor-icon.png'
import { TribunalPartySchema } from '@src/components/Schema/TribunalPartySchema'

interface Props {
  data: TribunalPartySchema[]
}

// 渲染行
function Line ({ name, date, isThief = false }: {name: string, date: string, isThief?: boolean}): ReactElement {
  if (name === null || name === undefined || name === '') {
    return <span />
  }
  return (
    <div className={style.line}>
      <span>
        <span className={style.label}>{isThief && '首席'}仲裁员</span>
        <span>{name}</span>
      </span>
      <span>{date}</span>
    </div>
  )
}

function Main ({ data }: Props): ReactElement {
  const [item, setItem] = useState<TribunalPartySchema|null>(null)
  const [date, setDate] = useState('')
  useEffect(() => {
    if (data.length > 0) {
      setItem(data[0])
    }
  }, [data])
  useEffect(() => {
    if (item !== null && typeof item.tribunal_assign_datetime === 'number') {
      setDate(moment(item.tribunal_assign_datetime * 1000).format('YYYY-MM-DD'))
    }
  }, [item])
  if (item === null || item.tribunal_status !== '完成') {
    return <span />
  }
  return (
    <div>
      <div className={style.header}>
        <div className={style.icon}>
          <img src={icon} />
        </div>
        <span>最终确认仲裁员</span>
      </div>
      <Line name={item.tribunal_arbitrator} date={date} isThief={item.tribunal_type === '三人庭'} />
      <Line name={item.tribunal_arbitrator1} date={date} />
      <Line name={item.tribunal_arbitrator2} date={date} />
    </div>
  )
}

export default Main
