import { $post, $get } from '@js/request'
import { compress } from '@src/assets/js/tool'

interface CardRes {
  name: string
  num: string
}

// 获取身份证信息
export async function getCardInfoRequest (url: string, type: string): Promise<CardRes> {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  const formData = new FormData()
  formData.append('file_url', url)
  formData.append('side', type === '正面' ? 'face' : 'back')
  const res = await $post('/api/common/card_ocr', formData, config)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

// 提交身份认证请求
export async function submitRequest (name: string, license: string, fileid: string, fileid1: string): Promise<any> {
  const res = await $post('/api/xd/certify', {
    name,
    license_type: '身份证',
    license: compress(license),
    fileid,
    fileid1,
    status: 1
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

// 获取案件列表
export async function getCaseListRequest (): Promise<number> {
  const res = await $get('/api/xd/case/findCaseByAccountid', {
    params: {
      current: 1,
      page_size: 1000
    }
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data.total
}
