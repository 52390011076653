import { $get } from '@js/request'

export interface Tree {
  title: string
  value: string
  children: Tree[]
}

// 获取案由树
export async function getTreeRequest (): Promise<Tree[]> {
  const res = await $get('/api/common/cause_action')
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}
