import React, { ReactElement, useState, useContext, useEffect, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { History } from 'history'
import _ from 'lodash'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import style from './Index.module.less'
import { TribunalPartySchema } from '@src/components/Schema/TribunalPartySchema'
import { Context } from '../../context'
import { Skeleton, Form, Space, Button, FormInstance, message, Alert } from 'antd'
import ArbitratorSelector from '@components/ArbitratorSelector/Index'
import { awaitWrap, scrollToAnchor } from '@src/assets/js/tool'
import { updateArbitratorRequest } from './ajax'
import { connect } from 'react-redux'
import { USERINFO } from '@src/redux/actions/basic'
import { BASIC_STATE } from '@src/redux/reducers/basic'

const { Item } = Form

interface Params {
  caseid: string
  id: string
}

interface Props {
  caseDetail: CaseSchema
  refreshCase: () => void
  userInfo: USERINFO
}

// 初始化当事人的仲裁员选择情况
function initData (list: TribunalPartySchema[], id: string, setData: (v: TribunalPartySchema) => void): void {
  for (let i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      setData(list[i])
      break
    }
  }
}

interface FormData {
  arbitrator: { id: string | undefined, name: string | undefined } | undefined
  arbitrator1: { id: string | undefined, name: string | undefined } | undefined
}

function formatSubmitData (d: FormData, party: TribunalPartySchema): TribunalPartySchema {
  const isThree = party.tribunal_type === '三人庭'
  const o = _.cloneDeep(party)
  if (d.arbitrator !== undefined) {
    if (d.arbitrator.id !== undefined && d.arbitrator.name !== undefined && d.arbitrator.id !== null && d.arbitrator.name !== null) {
      o.arbitrator = d.arbitrator.name
      o.arbitratorid = d.arbitrator.id
      if (o.arbitrator_sel_status !== '完成') {
        o.arbitrator_sel_datetime = Math.floor(Date.now() / 1000)
      }
    }
  }
  if (d.arbitrator1 !== undefined && isThree) {
    if (d.arbitrator1.id !== undefined && d.arbitrator1.name !== undefined && d.arbitrator1.id !== null && d.arbitrator1.name !== null) {
      o.arbitrator1 = d.arbitrator1.name
      o.arbitratorid1 = d.arbitrator1.id
      if (o.arbitrator1_sel_status !== '完成') {
        o.arbitrator1_sel_datetime = Math.floor(Date.now() / 1000)
      }
    }
  }
  return o
}

// 提交
async function submitHandle (d: FormData, party: TribunalPartySchema, h: History, c: CaseSchema, refreshCase: () => void): Promise<void> {
  const o = formatSubmitData(d, party)
  if (o.arbitratorid !== undefined && o.arbitratorid !== null && o.arbitratorid === o.arbitratorid1) {
    return await message.warning('两个仲裁员不能相同')
  }
  const [e] = await awaitWrap(updateArbitratorRequest(o))
  if (e === null) {
    h.push(`/admin/case-detail/${c.id}`)
    refreshCase()
    setTimeout(() => {
      scrollToAnchor('#party_zuting', h)
    }, 500)
    await message.success('提交成功')
  }
}

function initDay (d: CaseSchema, o: TribunalPartySchema, setDay: (v: number) => void): void {
  const list = [...d.applicants, ...d.respondents]
  const day = d.procedure === '简易程序' ? 5 : 10
  const id = o.partyid
  for (let i = 0; i < list.length; i++) {
    const o = list[i]
    if (o.id === id && ['港', '台', '澳', '国外', '外国', '港澳台'].includes(o.area)) {
      return setDay(day * 2)
    }
  }
  setDay(day)
}

function Main ({ caseDetail, refreshCase, userInfo }: Props): ReactElement {
  const formRef = useRef<FormInstance>(null)
  const c = useContext(Context)
  const m = useParams<Params>()
  const h = useHistory()
  const [title, setTitle] = useState('')
  const [data, setData] = useState<TribunalPartySchema|null>(null)
  const [day, setDay] = useState(5)
  useEffect(() => {
    initData(c.courtItems, m.id, setData)
    const used = c.usedOperates
    for (let i = 0; i < used.length; i++) {
      if (used[i].key === 'party_zuting') {
        setTitle(used[i].title)
        break
      }
    }
  }, [c, m])
  useEffect(() => {
    if (caseDetail !== null && data !== null) {
      initDay(caseDetail, data, setDay)
    }
  }, [c, data])
  useEffect(() => {
    const c = formRef.current
    const o: {[k: string]: any} = {}
    if (data !== null) {
      if (data.arbitratorid !== null && data.arbitrator !== null) {
        o.arbitrator = { id: data.arbitratorid, name: data.arbitrator }
      }
      if (data.arbitratorid1 !== null && data.arbitrator1 !== null) {
        o.arbitrator1 = { id: data.arbitratorid1, name: data.arbitrator1 }
      }
    }
    if (c !== null) {
      c.setFieldsValue(o)
    }
  }, [data])
  if (data === null) {
    return <Skeleton active />
  }
  return (
    <div>
      <div className={style.header}>{title}</div>
      <Alert className={style.alert} message={`请在收到通知之日起${day}日内选定仲裁员，选定是否有效以秘书最终确认为准`} type="warning" showIcon closable />
      <Form className={style.form} ref={formRef} labelCol={{ flex: '100px' }} onFinish={async d => await submitHandle(d, data, h, caseDetail, refreshCase)}>
        <Item label={`${data.tribunal_type === '三人庭' ? '首席' : ''}仲裁员`} name='arbitrator' rules={[{ required: true, message: '仲裁员不能为空' }]}>
          <ArbitratorSelector className={style.long} disabled={data.arbitrator_sel_status === '完成'} />
        </Item>
        {
          data.tribunal_type === '三人庭'
            ? (
            <Item label='仲裁员' name='arbitrator1' rules={[{ required: true, message: '仲裁员不能为空' }]}>
              <ArbitratorSelector className={style.long} disabled={data.arbitrator1_sel_status === '完成'} />
            </Item>
              )
            : null
        }

        <div className={style['btn-line']}>
          <Space>
            <Button htmlType='button' onClick={() => h.push(`/admin/case-detail/${m.caseid}`)}>取消编辑</Button>
            <Button type='primary' htmlType='submit' disabled={data.arbitrator_sel_status === '完成' && data.arbitrator1_sel_status === '完成'}>确认提交</Button>
          </Space>
        </div>
      </Form>
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo
  }
}, dispatch => {
  return {

  }
})(Main)
