import { ReactElement } from 'react'
import style from './Index.module.less'
import { NavLink } from 'react-router-dom'

function Main (): ReactElement {
  return (
    <div>
      <div className={style['page-title']}>案件缴费</div>
      <NavLink activeClassName={style.active} strict className={style.link} to='/admin/case-pay-list/case-list'>
        <span>待缴费案件</span>
      </NavLink>
      <NavLink activeClassName={style.active} strict className={style.link} to='/admin/case-pay-list/to-pay-list'>
        <span>待支付订单</span>
      </NavLink>
      <NavLink activeClassName={style.active} strict className={style.link} to='/admin/case-pay-list/paied-list'>
        <span>已支付订单</span>
      </NavLink>
    </div>
  )
}

export default Main
