import { ColumnsType } from 'antd/es/table'
import { formatDate, formatMoney } from '@src/assets/js/tool'
import { OrderCaseSchema } from '@src/components/Schema/OrderSchema'

export function getColumn (): ColumnsType<OrderCaseSchema> {
  const columns: ColumnsType<OrderCaseSchema> = [
    {
      title: '立案号/案号',
      dataIndex: 'caseno_desc',
      key: 'caseno_desc',
      width: '205px',
      render: (v: string, l) => {
        if (typeof v === 'string') {
          return v
        }
        return l.acceptno_desc_doc
      }
    },
    {
      title: '受理日期',
      dataIndex: 'accept_datetime',
      key: 'accept_datetime',
      width: '120px',
      render: (v: number) => {
        if (typeof v === 'number') {
          return formatDate(v)
        }
      }
    },
    {
      title: '申请人',
      dataIndex: 'applicant_name',
      key: 'applicant_name',
      ellipsis: true
    },
    {
      title: '被申请人',
      dataIndex: 'respondent_name',
      key: 'respondent_name',
      ellipsis: true
    },
    {
      title: '标的',
      dataIndex: 'dispute_amount',
      key: 'dispute_amount',
      align: 'right',
      width: '200px',
      render: (v: number) => {
        if (typeof v === 'number') {
          return formatMoney(v)
        }
      }
    },
    {
      title: '金额',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      width: '200px',
      render: (v: number) => {
        if (typeof v === 'number') {
          return <span style={{ color: 'rgba(255, 102, 0, 0.647)' }}>{formatMoney(v)}</span>
        }
      }
    }
  ]
  return columns
}
