import React, { ReactElement } from 'react'
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons'
import style from './Index.module.less'
import classNames from 'classnames'

type Fn = (v: string) => void

interface Props {
  value: string
  onChange: Fn
}

function Main ({ value, onChange }: Props): ReactElement {
  const clearName = classNames(style.clear, { [style.active]: value.length > 0 })
  return (
    <div className={style.container}>
      <div className={style.icon}>
        <SearchOutlined />
      </div>
      <input className={style.input} placeholder='搜索搜索案号/申请人/被申请人' type="text" value={value} onChange={e => onChange(e.target.value)} />
      <div className={clearName}>
        <CloseCircleOutlined className={style['clear-icon']} onClick={() => onChange('')} />
      </div>
    </div>
  )
}

export default Main
