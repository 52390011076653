import { $put, $post, $delete } from '@js/request'

// 更新或新增证据
export async function evdenceRequest (p: any): Promise<void> {
  let res
  const id: string = p.id
  delete p.id
  if (id === undefined) {
    res = await $post('/api/xd/evidence', p) // 新增数据
  } else {
    res = await $put(`/api/xd/evidence/${id}`, p) // 更新数据
  }
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

// 删除证据
export async function delEvidenceRequest (id: string): Promise<void> {
  const res = await $delete(`/api/xd/evidence/${id}`)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}
