import React, { ReactElement } from 'react'
import { Button } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import style from './Index.module.less'
import applicantIcon from '@imgs/applicant-icon.png'
// import respondentIcon from '@imgs/respondent-icon.png'
import { TribunalPartySchema } from '@src/components/Schema/TribunalPartySchema'

interface Props {
  data: TribunalPartySchema
}

// 渲染仲裁员选择结果行
function Line ({ name, id, date, isThief = false }: {name: string, id: string, date: number, isThief?: boolean}): ReactElement {
  const selected = name !== null && name !== undefined && name !== ''
  const time = typeof date === 'number' ? moment(date * 1000).format('YYYY-MM-DD') : ''
  return (
    <div className={style.line}>
      <span>
        <span className={style.label}>{isThief && '首席'}仲裁员</span>
        <span>{selected ? name : '未选定'}</span>
      </span>
      <span>{time}</span>
    </div>
  )
}

// 渲染边裁结果
function NormalLine ({ data }: Props): ReactElement {
  if (data.tribunal_type !== '三人庭') {
    return <span />
  }
  return <Line name={data.arbitrator1} id={data.arbitratorid1} date={data.arbitrator1_sel_datetime} />
}

// 渲染选择仲裁员按钮
function SubLine ({ data }: Props): ReactElement {
  const h = useHistory()
  const m = useParams<{caseid: string}>()
  if (data.status === '完成') {
    return <span />
  }
  return (
    <div className={style['btn-line']}>
      <Button type='primary' ghost onClick={() => h.push(`/admin/case-detail/${m.caseid}/court/${data.id}`)}>录入仲裁员</Button>
    </div>
  )
}

function Main ({ data }: Props): ReactElement {
  return (
    <div>
      <div className={style.header}>
        <div className={style.icon}>
          <img src={applicantIcon} />
        </div>
        <span>{data.party_name}选定</span>
      </div>
      <Line name={data.arbitrator} id={data.arbitratorid} date={data.arbitrator_sel_datetime} isThief={data.tribunal_type === '三人庭'} />
      <NormalLine data={data} />
      <SubLine data={data} />
    </div>
  )
}

export default Main
